<template>
  <div>
    <vue-headful :title="pageTitle"/>
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading/>
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <div id="datatable">
            <table class="table is-fullwidth">
              <tr>
                <th>Site Name</th>
                <th>Company Name</th>
                <th>Site URL</th>
                <!-- <th>&nbsp;</th> -->
              </tr>
              <tr v-for="site in sites" v-bind:key="site.id">
                <td>
                  <div class="field">
                    <div>
                      <router-link
                        :to="{ name: 'EditSite', params: { id:site.id, model: site }}"
                      >{{site.name}}</router-link>
                    </div>
                  </div>
                </td>
                <td>{{site.displayName}}</td>
                <td>{{site.url}}</td>
                <!-- <td>
                <a class="button is-danger is-small" v-on:click="deleteModel(site.id)">Delete</a>
                </td>-->
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
let pageName = 'Site Management'

export default {
  components: {
    Loading
  },
  data () {
    return {
      sites: [],
      isLoading: false,

      pageheading: pageName
    }
  },
  created () {
    this.getDataFromApi()
  },
  computed: {
    ...mapState([activeSite]),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  methods: {
    getDataFromApi () {
      this.isLoading = true
      this.$http
        .get('/sites')
        .then(response => {
          this.sites = response.data.records
          this.isLoading = false
        })
        .catch(error => {
          this.handleApiErr(error)
          this.isLoading = false
        })
    },
    deleteModel (id) {
      this.isLoading = true
      this.$http // TODO: refactor .then and .catch to async try catch site wide
        .delete(`/sites/${id}`)
        .then(resp => {
          this.getDataFromApi()
          this.isLoading = false
        })
        .catch(error => {
          this.handleApiErr(error)
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
</style>
